<template>
  <offer-form :base-offer="offer" view-mode="create"></offer-form>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import OfferForm from '@/components/organisms/modules/ecommerce/offer/form/OfferForm';
import CONFIG from '@/components/organisms/modules/ecommerce/offer/form/config';
import Offer from '@/entities/ecommerce/Offer';
import { COUNTRY_BY_ID, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import COST_TYPE_OPTIONS from '@/model/modules/ecommerce/offer/CostTypeOptions';
import { GET_CURRENCIES_REQUEST, CURRENCIES } from '@/store/modules/currency/keys';
import { getAdvertiserById } from '@/services/modules/ecommerce/advertiser';
import OfferAttribution from '@/entities/ecommerce/OfferAttribution';

export default {
  name: 'OfferCreate',
  components: {
    OfferForm,
  },
  data: () => ({
    offer: null,
    defaultAdvertiser: null,
  }),
  computed: {
    ...mapGetters({
      getCountryById: COUNTRY_BY_ID,
      currencyList: CURRENCIES,
    }),
    isNewOffer() {
      return !this.offerId || this.offerId === 'new';
    },
    advertiserId() {
      return this.$route.query.advertiserId;
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.advertiserId) {
      await this.loadAdvertiser();
    }
    await this.loadCountriesAndCurrency();
    this.defaultOffer();

    this.isLoading = false;
  },
  methods: {
    ...mapActions({ getCountries: GET_COUNTRIES_REQUEST, getCurrencies: GET_CURRENCIES_REQUEST }),
    async loadCountriesAndCurrency() {
      return Promise.all([this.getCountries(), this.getCurrencies()]);
    },
    async loadAdvertiser() {
      const advertiserData = await getAdvertiserById(this.advertiserId);
      this.defaultAdvertiser = advertiserData?.data;
    },
    defaultOffer() {
      this.offer = new Offer();
      this.offer.status = CONFIG.statusOptions[0].value;
      this.offer.priceType = COST_TYPE_OPTIONS[0].value;
      this.offer.country = this.getCountryById('ES');
      const defaultCurrency = this.currencyList.find(currency => currency.id === 'EUR');
      this.defaultCurrency = defaultCurrency?.id;
      this.offer.currency = defaultCurrency || this.activeClient?.currency;

      const monthLength = CONFIG.lengthOfTimeOptions.find(opt => opt.name === '30 day').value;
      this.offer.offerAttribution = new OfferAttribution(true, monthLength, false, monthLength);
      this.offer.advertiser = this.defaultAdvertiser;
    },
  },
};
</script>
